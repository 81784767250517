
import CategoryRoutes from './Category/router';
import DepartmentRoutes from './Department/router';
import BrandRoutes from './Brand/router';

const Index = () => import(/* webpackChunkName: "product" */ './index.vue');
const List = () => import(/* webpackChunkName: "product" */ './components/List.vue');
const InventoryAdj = () => import(/* webpackChunkName: "product" */ './components/InventoryAdj.vue');
const Home = () => import(/* webpackChunkName: "product" */ './view/Home.vue');

let routes = [];
routes = routes.concat(CategoryRoutes);
routes = routes.concat(DepartmentRoutes);
routes = routes.concat(BrandRoutes);



routes = routes.concat([
  {
  path: 'home',
  name: 'product-home',
  component: Home,
},
{
  path: 'list',
  name: 'product-list',
  component: List,
},
{
  path: 'inventoryAdj/:id',
  name: 'inventory-adj',
  component: InventoryAdj,
},
]);

export default [{
path: '/product',
redirect: { name: 'product-home' },
component: Index,
children: routes,
}];

// export default [
//   {
//     path: 'product',
//     name: 'product',
//     component: Index,
//     title: 'Product',
//     redirect: { name: 'product-list' },
//     children: [
//       {
//         path: 'list',
//         name: 'product-list',
//         component: List,
//       },
//       // {
//       //   path: 'edit',
//       //   name: 'product-edit',
//       //   component: Edit,
//       // },
//     ],
//   },
// ]
