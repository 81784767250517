
import TechLocationRoutes from './TechLocation/router';
import TechnicianRoutes from './Technician/router';

const Index = () => import(/* webpackChunkName: "workOrder" */ './index.vue');
const List = () => import(/* webpackChunkName: "workOrder" */ './components/List.vue');
const Home = () => import(/* webpackChunkName: "workOrder" */ './view/Home.vue');

let routes = [];
routes = routes.concat(TechLocationRoutes);
routes = routes.concat(TechnicianRoutes);

routes = routes.concat([
  {
  path: 'home',
  name: 'workOrder-home',
  component: Home,
},
{
  path: 'list',
  name: 'workOrder-list',
  component: List,
}
]);

export default [{
path: '/workOrder',
redirect: { name: 'workOrder-home' },
component: Index,
children: routes,
}];

