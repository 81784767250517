const Index = () => import(/* webpackChunkName: "adminTools" */ './index.vue');
const Home = () => import(/* webpackChunkName: "adminTools" */ './view/Home.vue');


export default [
  {
    path: '/adminTools',
    name: 'adminTools',
    component: Index,
    title: 'adminTools',
    redirect: { name: 'adminTools-home' },
    children: [      
      {
        path: 'home',
        name: 'adminTools-home',
        component: Home,
      },
    ],
  },
]