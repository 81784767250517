const Index = () => import( /* webpackChunkName: "account" */ './index.vue');
const Profile = () => import( /* webpackChunkName: "account" */ './view/Profile.vue');
const Home = () => import( /* webpackChunkName: "account" */ './components/Home.vue');

export default [{
  path: '/account',
  name: 'account',
  component: Index,
  title: 'account',
  redirect: {
    name: 'account-home'
  },
  children: [{
      path: 'home',
      name: 'account-home',
      component: Home,
    },
    {
      path: ':id/profile',
      name: 'account-profile',
      component: Profile,
    },


  ],
}, ]