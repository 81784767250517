<template>
  <v-toolbar dense flat :color="color">
    <v-app-bar-nav-icon v-if="showBackButton" @click="$router.go(-1)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>
    <v-spacer> </v-spacer>
    <slot></slot>
    <v-btn v-if="access" @click="$emit('add')" outlined rounded raised small>
      <v-icon small>add</v-icon> New
    </v-btn>
  </v-toolbar>
</template>
<script>
export default {
  name: "list-title-bar",
  props: {
    title: {
      default: 'Title'
    },
    color: {
      default: 'grey lighten-3'
    },
    access: {
      default: true
    },
    showBackButton: {
      default: true
    },
  }
};
</script>
