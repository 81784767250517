const Index = () => import(/* webpackChunkName: "techLocation" */ "./index.vue");
const List = () => import(/* webpackChunkName: "techLocation" */ "./components/List.vue");


export default [
  {
    path: 'techLocation',
    name: 'techLocation',
    component: Index,
    title: 'techLocation',
    redirect: { name: 'techLocation-list' },
    children: [
      {
        path: 'list',
        name: 'techLocation-list',
        component: List,
      },
    ],
  },
]
