
const Index = () => import(/* webpackChunkName: "lead" */ './index.vue');
const Profile = () => import(/* webpackChunkName: "lead" */ './view/Profile.vue');
const List = () => import(/* webpackChunkName: "lead" */ './components/List.vue');

export default [
  {
    path: 'lead',
    name: 'lead',
    component: Index,
    title: 'lead',
    redirect: { name: 'lead-list' },
    children: [      
      {
        path: ':id/profile',
        name: 'lead-profile',
        component: Profile,
      },
      {
        path: 'list',
        name: 'lead-list',
        component: List,
      },      
    ],
  },
]
