const Index = () => import(/* webpackChunkName: "expense" */ "./index.vue");
const ExpenseByDate = () => import(/* webpackChunkName: "expense" */ "./ExpenseByDate.vue");
const ExpenseByPayee = () => import(/* webpackChunkName: "expense" */ "./ExpenseByPayee.vue");
const PayeeList = () => import(/* webpackChunkName: "expense" */ "./PayeeList.vue");

export default [
  {
    path: 'expense',
    name: 'expense',
    component: Index,
    redirect: { name: 'expense-list-report' },
    children: [
      {
        path: 'list',
        name: 'expense-list-report',
        component: ExpenseByDate,
      },
      {
        path: 'byPayee',
        name: 'expense-by-payee',
        component: ExpenseByPayee,
      },
      {
        path: 'payee/list',
        name: 'report-payee-list',
        component: PayeeList,
      },
    ],
    
  },
]
