import Vue from 'vue';

import BackBtn from '@/components/BackBtn.vue';
import ListTitle from '@/components/ListTitle.vue';
import ModelTitle from '@/components/ModelTitle.vue';


Vue.component('BackBtn', BackBtn)
Vue.component('ListTitle', ListTitle)
Vue.component('ModelTitle', ModelTitle)
