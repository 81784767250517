
const Index = () => import(/* webpackChunkName: "setting" */ './index.vue');

export default [
  {
    path: 'count',
    name: 'count',
    component: Index,
    title: 'count'
  },
]
