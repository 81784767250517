import Index from './index.vue'
const ByProduct = () => import("./ByProduct");
const BySalesman = () => import("./BySalesman");
const byCustomers = () => import("./byCustomers");

export default [{
  path: 'sale',
  name: 'sale',
  component: Index,
  redirect: {
    name: 'sale-by-product'
  },
  children: [{
      path: 'byProduct',
      name: 'sale-by-product-list',
      component: ByProduct,
    },
    {
      path: 'bySalesman',
      name: 'sale-by-salesman-list',
      component: BySalesman,
    },
    {
      path: 'byCustomers',
      name: 'sale-by-customer-list',
      component: byCustomers,
    },
  ],

}, ]