
import PayeeRoutes from './Payee/router';
const Index = () => import(/* webpackChunkName: "expense" */ './index.vue');
const List = () => import(/* webpackChunkName: "expense" */ './components/List.vue');
const Home = () => import(/* webpackChunkName: "expense" */ './view/Home.vue');

let routes = [];
routes = routes.concat(PayeeRoutes);

routes = routes.concat([{
  path: 'home',
  name: 'expense-home',
  component: Home,
},
  {
    path: 'list',
    name: 'expense-list',
    component: List,
  },
]);

export default [{
  path: '/expense',
  redirect: { name: 'expense-home' },
  component: Index,
  children: routes,
}];
