<template>
  <v-toolbar dense class="mb-3" :dark="dark" flat :color="color">
    <v-toolbar-title class="subtitle-1">
      {{ title }}
    </v-toolbar-title>
    <v-spacer> </v-spacer>
    <v-btn v-if="showBackButton" small icon @click.stop.prevent="$emit('close')">
      <v-icon small>close</v-icon>
    </v-btn>
  </v-toolbar>
</template>
<script>
export default {
  name: "list-title-bar",
  props: {
    title: {
      default: "Title"
    },
    dark: {
      default: false
    },
    color: {
      default: 'grey lighten-3'
    },
    access: {
      default: true
    },
    showBackButton: {
      default: true
    }
  }
};
</script>
