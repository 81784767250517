const Index = () => import(/* webpackChunkName: "authentication" */ './index');
const Login = () => import(/* webpackChunkName: "authentication" */ './components/Login.vue');
const SignUp = () => import(/* webpackChunkName: "authentication" */ './components/SignUp.vue');
const Logout = () => import(/* webpackChunkName: "authentication" */ './components/Logout.vue');
const ForgotCode = () => import(/* webpackChunkName: "authentication" */ './components/ForgotCode.vue');

export default [
  {
    path: '/',
    name: 'login',
    component: Index,
    title: 'Login',
    redirect: { name: 'authentication-login' },
    children: [
      {
        path: '/login',
        name: 'authentication-login',
        component: Login,
        props: true,
      },
      {
        path: '/signup',
        name: 'signup',
        component: SignUp,
        props: true,
      },
      {
        path: '/forgot',
        name: 'forgot',
        component: ForgotCode,
        props: true,
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
]
