
const Index = () => import(/* webpackChunkName: "invoice" */ "./index");
const DueInvoice = () => import(/* webpackChunkName: "invoice" */ "./DueInvoice");
const DueInvoiceByDate = () => import(/* webpackChunkName: "invoice" */ "./DueInvoiceByDate");
const RefundInvoice = () => import(/* webpackChunkName: "invoice" */ "./RefundInvoice");

export default [{
  path: 'invoice',
  name: 'invoice-report',
  component: Index,
  redirect: {
    name: 'due-invoice'
  },
  children: [{
      path: 'due',
      name: 'due-invoice',
      component: DueInvoice,
    },
    {
      path: 'dueByDate',
      name: 'due-invoice-by-date',
      component: DueInvoiceByDate,
    },
    {
      path: 'refundInvoice',
      name: 'refund-invoice',
      component: RefundInvoice,
    }],
}, ]