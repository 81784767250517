import Index from './index.vue'
import List from './components/List.vue'
import Deleted from './components/DeletedPayments.vue'
import Home from './view/Home.vue'

export default [
  {
    path: 'payment',
    name: 'payment',
    component: Index,
    title: 'payment',
    redirect: { name: 'payment-list' },
    children: [      
      {
        path: 'list',
        name: 'payment-list',
        component: List,
      },
      {
        path: 'home',
        name: 'payment-create',
        component: Home,
      },     
      {
        path: 'deleted',
        name: 'deleted-payment',
        component: Deleted,
      },       
    ],
  },
]
