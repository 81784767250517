const Index = () => import(/* webpackChunkName: "global" */ './index');
const ViewInvoice = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/View.vue');
const ViewDeletedInvoice = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/deleted/View.vue');
const ViewReport = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/SalesmanReport.vue');
const ViewOpenInvoice = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/view/OpenInvoice.vue');
const PackingSlip = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/PackingSlip');
const DeletedPackingSlip = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/deleted/PackingSlip');
const ViewPruchase = () => import(/* webpackChunkName: "global" */ '@/modules/Inventory/Purchase/components/View');
const ViewAvailableIMEI = () => import(/* webpackChunkName: "global" */ '@/modules/Inventory/Purchase/components/ProductIMEIView.vue');
const ViewPurchasePayment = () => import(/* webpackChunkName: "global" */ '@/modules/Inventory/Payment/components/View');
const ViewPayment = () => import(/* webpackChunkName: "global" */ '@/modules/Customer/Payment/components/View');
const viewStatement = () => import(/* webpackChunkName: "global" */ '@/modules/Invoice/components/Statement');
const ViewOrder = () => import(/* webpackChunkName: "global" */ '@/modules/Order/InHouse/components/View');
const viewPurchaseOrder = () => import(/* webpackChunkName: "global" */ '@/modules/Inventory/PurchaseOrder/components/View');
const PurchasePackingSlip = () => import(/* webpackChunkName: "global" */ '@/modules/Inventory/Purchase/components/PackingSlip');


export default [
  {
    path: '/global',
    name: 'invoice-global',
    component: Index,
    redirect: { name: 'view-invoiced' },
    children: [
      {
        path: 'invoice/:id/view',
        name: 'view-invoice',
        component: ViewInvoice,
      },
      {
        path: 'invoice/deleted/:id/view',
        name: 'view-invoice-deleted',
        component: ViewDeletedInvoice,
      },
      {
        path: 'salesman/:id/view',
        name: 'view-salsman-report',
        component: ViewReport,
      },
      {
        path: 'print/availableIMEI/:id/view',
        name: 'view-available-imei',
        component: ViewAvailableIMEI,
      },
      {
        path: 'purchase/:id/view',
        name: 'view-purchase',
        component: ViewPruchase,
      },
      {
        path: 'purchase/:id/packingSlip',
        name: 'view-purchase-pickingslip',
        component: PurchasePackingSlip,
      },
      {
        path: 'order/:id/view',
        name: 'view-order',
        component: ViewOrder,
      },
      {
        path: 'purchaseOrder/:id/view',
        name: 'view-purchase-order',
        component: viewPurchaseOrder,
      },
      {
        path: 'payment/:id/view',
        name: 'view-payment',
        component: ViewPayment,
      },
      {
        path: 'purchase/payment/:id/view',
        name: 'view-purchase-payment',
        component: ViewPurchasePayment,
      },
      {
        path: 'statement/:id/view',
        name: 'view-statement',
        component: viewStatement,
      },
      {
        path: 'invoice/:id/packingSlip',
        name: 'view-pickingslip',
        component: PackingSlip,
      },
      {
        path: 'invoice/deleted/:id/packingSlip',
        name: 'view-pickingslip-deleted',
        component: DeletedPackingSlip,
      },
      {
        path: 'invoice/:id/openInvoice',
        name: 'view-open-invoice',
        component: ViewOpenInvoice,
      },
    ],
  },
]
