<template>
  <v-btn small icon class="mr-0" @click.stop.prevent="$router.go(-1)">
    <v-icon>mdi-chevron-left</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "back-button"
};
</script>
