
const Index = () => import(/* webpackChunkName: "order" */ "./index");
const OrderByDate = () => import(/* webpackChunkName: "order" */ "@/modules/ReportView/components/order/OrderByDate.vue");
// const DueInvoiceByDate = () => import(/* webpackChunkName: "order" */ "./DueInvoiceByDate");
// const RefundInvoice = () => import(/* webpackChunkName: "order" */ "./RefundInvoice");

export default [{
  path: 'order',
  name: 'order-report',
  component: Index,
  redirect: {
    name: 'order-by-date'
  },
  children: [{
      path: 'byDate',
      name: 'order-by-date',
      component: OrderByDate,
    },
    // {
    //   path: 'dueByDate',
    //   name: 'due-order-by-date',
    //   component: DueInvoiceByDate,
    // },
    // {
    //   path: 'order',
    //   name: 'refund-order',
    //   component: RefundInvoice,
    // }
  ],
}, ]