import Index from './index.vue'
const PaymentByDate = () => import("./PaymentByDate");
const PaymentByMethod = () => import("./PaymentByMethod");

export default [{
  path: 'payment',
  name: 'payment-report',
  component: Index,
  redirect: {
    name: 'payment-list-report'
  },
  children: [
    {
      path: 'list',
      name: 'payment-list-report',
      component: PaymentByDate,
    },
    {
      path: 'method',
      name: 'payment-method',
      component: PaymentByMethod,
    },

  ],

}, ]