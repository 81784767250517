import Index from './index.vue'
const ByDate = () => import("./ByDate");
const VendorList = () => import("./VendorList");

export default [{
  path: 'purchase',
  name: 'purchase',
  component: Index,
  redirect: {
    name: 'purchase-by-date'
  },
  children: [{
      path: 'bydate',
      name: 'purchase-by-date',
      component: ByDate,
    },
    {
      path: 'vendor',
      name: 'vendor-list',
      component: VendorList,
    },
  ],

}, ]