// utilsPlugin.js

import { highlight } from '@/Filter/utils.js';

const UtilsPlugin = {
  install(Vue) {
    Vue.prototype.$highlight = highlight;
  }
};

export default UtilsPlugin;