import backofficeService from '../../../api/backoffice'
// let paymentPosted = new Event('invoiceCreated')


export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  getAll(params) {
    return backofficeService.Api().get('payment', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  groupByCustomer(params) {
    return backofficeService.Api().get('payment/groupByCustomer', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  groupByPaymentMethod(params) {
    return backofficeService.Api().get('payment/paymentType', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`payment/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getDetailById(id, params = {}) {
    return backofficeService.Api().get(`/paymentDetail/payment/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  last(params = {}) {
    return backofficeService.Api().get(`paymentDetail/last`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getAllPaymentDetail(params = {}) {
    return backofficeService.Api().get(`paymentDetail`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getAllDeletedPaymentDetail(params = {}) {
    return backofficeService.Api().get(`paymentDetail/deleted`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  /**
   * Create a product. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('payment', data)
      .then(response => {
        // document.dispatchEvent(paymentPosted)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Update specific product
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`payment/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`payment/${id}`, {
        params
      })
      .then(response => {
        // document.dispatchEvent(paymentPosted)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  deletePaymentDetail(id, params) {
    return backofficeService.Api().delete(`paymentDetail/${id}`, {
        params
      })
      .then(response => {
        // document.dispatchEvent(paymentPosted)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

}