const Index = () => import('./index.vue');
const ViewInvoice = () => import('@/modules/Invoice/components/View');
const ViewOpenInvoice = () => import('@/modules/Invoice/view/OpenInvoice.vue');
const PackingSlip = () => import('@/modules/Invoice/components/PackingSlip');
const ViewPruchase = () => import('@/modules/Inventory/Purchase/components/View');
const viewStatement = () => import('@/modules/Invoice/components/Statement');
const ViewPayment = () => import('@/modules/Customer/Payment/components/View');
const ViewOrder = () => import('@/modules/Order/InHouse/components/View');


export default [{
  path: '/public',
  name: 'public-view',
  component: Index,
  redirect: {
    name: 'view-invoiced'
  },
  children: [{
      path: 'invoice/:id/view',
      name: 'view-invoice-public',
      component: ViewInvoice,
      meta: {
        public: true
      }
    },
    {
      path: 'purchase/:id/view',
      name: 'view-purchase-public',
      component: ViewPruchase,
      meta: {
        public: true
      }
    },
    {
      path: 'order/:id/view',
      name: 'view-order-public',
      component: ViewOrder,
      meta: {
        public: true
      }
    },
    {
      path: 'payment/:id/view',
      name: 'view-payment-public',
      component: ViewPayment,
      meta: {
        public: true
      }
    },
    {
      path: 'statement/:id/view',
      name: 'view-statement-public',
      component: viewStatement,
      meta: {
        public: true
      }
    },
    {
      path: 'invoice/:id/packingSlip',
      name: 'view-pickingslip-public',
      component: PackingSlip,
      meta: {
        public: true
      }
    },
    {
      path: 'invoice/:id/openInvoice',
      name: 'view-open-invoice-public',
      component: ViewOpenInvoice,
      meta: {
        public: true
      }
    },
  ],
}, ]