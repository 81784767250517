import Index from './index.vue'
const InventoryList = () => import("@/modules/ReportView/components/inventory/InventoryList.vue");
const AvailableSerial = () => import("@/modules/ReportView/components/inventory/AvailableSerial.vue");
const InventoryValue = () => import("@/modules/ReportView/components/inventory/InventoryValue.vue");

export default [{
  path: 'inventory',
  name: 'inventory',
  component: Index,
  redirect: {
    name: 'inventory-list'
  },
  children: [{
      path: 'serial',
      name: 'inventory-list',
      component: InventoryList,
    },
    {
      path: 'availableSerial',
      name: 'available-serial-by',
      component: AvailableSerial,
    },
    {
      path: 'inventoryValue',
      name: 'inventory-value',
      component: InventoryValue,
    },
  ],

}, ]