const Index = () => import(/* webpackChunkName: "catalog" */ './index.vue');
// const List = () => import(/* webpackChunkName: "catalog" */ './components/List.vue');
const Create = () => import(/* webpackChunkName: "catalog" */ './components/Create.vue');
// const Edit = () => import(/* webpackChunkName: "catalog" */ './components/Edit.vue');
// const Home = () => import(/* webpackChunkName: "catalog" */ './view/Home.vue');


export default [
  {
    path: '/catalog',
    name: 'catalog',
    component: Index,
    redirect: { name: 'catalog-create' },
    title: 'catalog',
    children: [      
      // {
      //   path: 'home',
      //   name: 'catalog-home',
      //   component: Home,
      // },
      // {
      //   path: 'list',
      //   name: 'catalog-list',
      //   component: List,
      // },
      {
        path: 'create',
        name: 'catalog-create',
        component: Create,
      },
      // {
      //   path: ':id/edit',
      //   name: 'catalog-edit',
      //   component: Edit,
      // },    
    ],
  },
]
