import Vue from 'vue';

Vue.prototype.$style = {
  inputBox: {
    mandatory: '#FFFDE7',
  },
  listTotal: {
    filter: '#fffdf8',
    result: '#fff6df'
  },
  purchase: {
    titlebar: '#fff6df',
  },
}
