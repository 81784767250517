<template>
  <v-container grid-list-lg fluid>
    <PaymentMethodLine v-if="!$vuetify.breakpoint.xsOnly" />
    <List />
  </v-container>
</template>

<script>
import PaymentMethodLine from "@/modules/Customer/Payment/Chart/PaymentMethodLine";
import List from "@/modules/Customer/Payment/components/PaymentList.vue";
export default {
  components: {
    PaymentMethodLine,
    List
  }
};
</script>

<style scoped></style>
