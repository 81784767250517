const Index = () => import(/* webpackChunkName: "technician" */ "./index.vue");
const List = () => import(/* webpackChunkName: "technician" */ "./components/List.vue");


export default [
  {
    path: 'technician',
    name: 'technician',
    component: Index,
    title: 'technician',
    redirect: { name: 'technician-list' },
    children: [
      {
        path: 'list',
        name: 'technician-list',
        component: List,
      },
    ],
  },
]
