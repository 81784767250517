import PurchaseRoutes from './Purchase/router';
import PurchaseOrderRoutes from './PurchaseOrder/router';
import VendorRoutes from './Vendor/router';
import SerialRoutes from './Serial/router';
import CountRoutes from './Count/router';

const Index = () => import('./index.vue');
const Home = () => import('./views/Home.vue');

let routes = [];

routes = routes.concat(PurchaseRoutes);
routes = routes.concat(VendorRoutes);
routes = routes.concat(SerialRoutes);
routes = routes.concat(CountRoutes);
routes = routes.concat(PurchaseOrderRoutes);

routes = routes.concat([
  {
    path: '',
    name: 'inventory-home',
    component: Home,
  },
]);

export default [
  {
    path: '/inventory',
    component: Index,
    title: 'Inventory',
    children: routes,
  },
];
