const Index = () => import(/* webpackChunkName: "report" */ './index.vue');
const Home = () => import(/* webpackChunkName: "report" */ './view/Home.vue');

export default [
  {
    path: '/report',
    name: 'report',
    component: Index,
    title: 'report',
    redirect: { name: 'report-home' },
    children: [      
      {
        path: 'home',
        name: 'report-home',
        component: Home,
      },
    ],
  },
]