// import Index from './index.vue'
// import List from './components/List.vue'
// import DeletedInvoice from './components/DeletedInvoice.vue'
// import Create from './components/Create.vue'
// import Edit from './components/Edit.vue'
// import Home from './view/Home.vue'
// import AdjustCost from './components/AdjustCost.vue'

const Index = () => import(/* webpackChunkName: "invoice" */ './index.vue');
const List = () => import(/* webpackChunkName: "invoice" */ './components/List.vue');
const DeletedInvoice = () => import(/* webpackChunkName: "invoice" */ './components/DeletedInvoice.vue');
const Create = () => import(/* webpackChunkName: "invoice" */ './components/Create.vue');
const Edit = () => import(/* webpackChunkName: "invoice" */ './components/Edit.vue');
const Home = () => import(/* webpackChunkName: "invoice" */ './view/Home.vue');
const AdjustCost = () => import(/* webpackChunkName: "invoice" */ './components/AdjustCost.vue');



export default [
  {
    path: '/invoice',
    name: 'invoice',
    component: Index,
    redirect: { name: 'invoice-home' },
    title: 'invoice',
    children: [      {
      path: 'home',
      name: 'invoice-home',
      component: Home,
    },
      {
        path: 'list',
        name: 'invoice-list',
        component: List,
      },
      {
        path: 'deleted',
        name: 'deleted-invoice-list',
        component: DeletedInvoice,
      },
      {
        path: 'create',
        name: 'invoice-create',
        component: Create,
        props: true,
      },
      {
        path: ':id/edit',
        name: 'invoice-edit',
        component: Edit,
      },
      {
        path: 'adjustCost',
        name: 'adjust-cost',
        component: AdjustCost,
      },
    ],
  },
]
