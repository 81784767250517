const Index = () => import(/* webpackChunkName: "remoteOrder" */ './index.vue');
const List = () => import(/* webpackChunkName: "remoteOrder" */ './components/List.vue');
const Create = () => import(/* webpackChunkName: "remoteOrder" */ './components/Create.vue');
const Edit = () => import(/* webpackChunkName: "remoteOrder" */ './components/Edit.vue');
const Home = () => import(/* webpackChunkName: "remoteOrder" */ './view/Home.vue');


export default [
  {
    path: '/remote',
    name: 'remote',
    component: Index,
    redirect: { name: 'remote-home' },
    title: 'remote',
    children: [      
      {
        path: 'home',
        name: 'remote-home',
        component: Home,
      },
      {
        path: 'list',
        name: 'remote-list',
        component: List,
      },
      {
        path: 'create',
        name: 'remote-create',
        component: Create,
      },
      {
        path: ':id/edit',
        name: 'remote-edit',
        component: Edit,
      },    
    ],
  },
]
