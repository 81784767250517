const Index = () => import(/* webpackChunkName: "category" */ './index.vue');
const List = () => import(/* webpackChunkName: "category" */ './components/List.vue');

export default [
  {
    path: 'category',
    name: 'category',
    component: Index,
    title: 'category',
    redirect: { name: 'category-list' },
    children: [
      
      {
        path: 'list',
        name: 'category-list',
        component: List,
      },
      
    ],
  },
]
