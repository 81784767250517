
const Index = () => import(/* webpackChunkName: "department" */ './index.vue');
const List = () => import(/* webpackChunkName: "department" */ './components/List.vue');

export default [
  {
    path: 'department',
    name: 'department',
    component: Index,
    title: 'department',
    redirect: { name: 'department-list' },
    children: [
      {
        path: 'list',
        name: 'department-list',
        component: List,
      },      
    ],
  },
]
