
const Index = () => import(/* webpackChunkName: "remote" */ './index.vue');
const Home = () => import(/* webpackChunkName: "remote" */ '@/modules/Order/RemoteOrderView/view/Home');

export default [{
  path: '/remoteOrder',
  name: 'remote-order-view',
  component: Index,
  redirect: {
    name: 'view-home'
  },
  children: [
    {
      path: ':id/start',
      name: 'view-home',
      component: Home,
      meta: {
        order: true
      }
    },
    // {
    //   path: ':id/order',
    //   name: 'view-category',
    //   component: Category,
    //   meta: {
    //     order: true
    //   }
    // },    
  ],
}, ]