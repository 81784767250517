<template>
  <v-card :loading="loading" outlined>
    <ListTitle
      v-if="hideTitle"
      :access="false"
      title="Payment Methods"
      :showBackButton="false"
    />
    <div id="stock-line"></div>
  </v-card>
</template>

<script>
import paymentService from "@/modules/Customer/Payment/service";
// import $ from "jquery";
import Highcharts from "highcharts";

export default {
  props: {
    hideTitle: {
      default: true
    },
    start: {
      default: null
    },
    end: {
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      let where = {};
      if (this.start) {
        where.start = this.start;
        where.end = this.end;
      }
      return paymentService.groupByPaymentMethod(where).then(response => {
        if (response.data) {
          var newData = [];
          response.data.forEach(row => {
            newData.push([row.paymentMethod, row.totalPayment | 0]);
          });
          newData.sort(function(a, b) {
            return b[1] - a[1];
          });
          this.loading = false;

          Highcharts.chart("stock-line", {
            credits: false, // Disable HighCharts logo// Set Colors
            chart: {
              type: "column"
            },
            title: false,
            xAxis: {
              type: "category",
              labels: {
                rotation: -45,
                style: {
                  fontSize: "13px",
                  fontFamily: "Verdana, sans-serif"
                }
              }
            },
            yAxis: {
              title: {
                text: "Sales"
              },
              labels: {
                formatter: function() {
                  return this.value / 1000000 + "m";
                }
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              pointFormat: "{series.name} is collected: <b>{point.y:.2f} </b>"
            },
            series: [
              {
                name: "Payment",
                data: newData,
                color: "#FBC02D",
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.2f}", // one decimal
                  style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif"
                  }
                }
              }
            ]
          });
        }
      });
    }
  }
};
</script>
