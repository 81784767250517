const Index = () => import(/* webpackChunkName: "vendor" */ "./index.vue");
const Create = () => import(/* webpackChunkName: "vendor" */ "./components/Create.vue");
const List = () => import(/* webpackChunkName: "vendor" */ "./components/List.vue");
const Edit = () => import(/* webpackChunkName: "vendor" */ "./components/Edit.vue");
const Profile = () => import(/* webpackChunkName: "vendor" */ "./components/Profile.vue");


export default [
  {
    path: 'vendor',
    name: 'vendor',
    component: Index,
    title: 'vendor',
    redirect: { name: 'vendor-list' },
    children: [
      {
        path: 'create',
        name: 'vendor-create',
        component: Create,
      },
      {
        path: 'list',
        name: 'vendor-list',
        component: List,
      },
      {
        path: ':id/edit',
        name: 'vendor-edit',
        component: Edit,
      },
      {
        path: ':id/profile',
        name: 'vendor-profile',
        component: Profile,
      },
    ],
  },
]
