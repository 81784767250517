// import Inbox from './components/Inbox'
const Index = () => import(/* webpackChunkName: "home" */ './index.vue');

export default [
  {
    path: '/',
    name: 'home',
    component: Index,
    title: 'Home',
    // redirect: { name: 'inbox-view' },
  },
]
