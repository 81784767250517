import Vue from 'vue'
import vuetify from './plugins/vuetify';
import './plugins/vue-progressbar'
import i18n from './plugins/vueI18n'
import './plugins/vue-currency-filter'
import './plugins/vue-moment'
import './plugins/vue-sweetalert2'
import './plugins/v-mask'
import './plugins/style';
import './plugins/vue-events'
import './plugins/vue-json-excel';
import './plugins/csp-component'
// import './plugins/vue-google-oauth2'
import './plugins/vue-js-toggle-button'
import './plugins/vue-date-time-picker'
import './plugins/vue-youtube'
import './plugins/portal-vue'
import './Filter/USPhoneNumber'
import UtilsPlugin from './plugins/utilsPlugin';
import VueGtag from "vue-gtag";


import App from './App.vue'
import router from './router';
import store from './plugins/vuex'

import './registerServiceWorker';

Vue.use(UtilsPlugin);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }  
},router);

// Vue.use(VueGtag, {
//   id: process.env.VUE_APP_GOOGLE_ANALYTICS,
//   router,
//   debug: {
//     enabled: false,
//     sendHitTask: false,
//   },
// });

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event")),
}).$mount('#app')
