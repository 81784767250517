import Index from './index.vue'
const StandardProfitAndLost = () => import("./StandardProfitAndLost");
const ByProduct = () => import("./ByProduct");

export default [
  {
    path: 'profitAndLost',
    name: 'profitAndLost',
    component: Index,
    redirect: { name: 'standard-profit-and-lost' },
    children: [
      {
        path: 'standard',
        name: 'standard-profit-and-lost',
        component: StandardProfitAndLost,
      },   
      {
        path: 'byProduct',
        name: 'profit-by-product',
        component: ByProduct,
      },       
    ],
    
  },
]
