const Index = () => import(/* webpackChunkName: "user" */ './index.vue');
const List = () => import(/* webpackChunkName: "user" */ './components/List.vue');
const Create = () => import(/* webpackChunkName: "user" */ './components/Create.vue');
const Edit = () => import(/* webpackChunkName: "user" */ './components/Edit.vue');

export default [
  {
    path: 'user',
    name: 'user',
    component: Index,
    title: 'User',
    redirect: { name: 'user-list' },
    children: [
      {
        path: 'list',
        name: 'user-list',
        component: List,
      },
      {
        path: 'create',
        name: 'user-create',
        component: Create,
      },
      {
        path: 'edit/:id',
        name: 'user-edit',
        component: Edit,
      },
      // {
      //   path: 'profile/:id',
      //   name: "user-profile",
      //   component: Profile
      // }
    ],
    
  },
]
