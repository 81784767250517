
const Index = () => import(/* webpackChunkName: "product-report" */ "./index");
const ProductList = () => import(/* webpackChunkName: "product-report" */ "./ProductList");
const ProductHistory = () => import(/* webpackChunkName: "product-report" */ "./History");

export default [{
  path: 'product',
  name: 'product',
  component: Index,
  redirect: {
    name: 'product-list-report'
  },
  children: [
    {
    path: 'list',
    name: 'product-list-report',
    component: ProductList,
  }, 
  {
    path: 'history/:id',
    name: 'product-history',
    component: ProductHistory,
  }, 
],

}, ]