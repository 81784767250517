
const Index = () => import(/* webpackChunkName: "setting" */ './index.vue');

export default [
  {
    path: 'setting',
    name: 'setting',
    component: Index,
    title: 'setting'
  },
]
