import PaymentRoutes from './Payment/router';

const Index = () => import(/* webpackChunkName: "customer" */ './index.vue');
const List = () => import(/* webpackChunkName: "customer" */ './view/CustomerList.vue');
const DeletedList = () => import(/* webpackChunkName: "customer" */ './view/DeletedCustomerList.vue');
const Profile = () => import(/* webpackChunkName: "customer" */ './view/Profile.vue');
const Home = () => import(/* webpackChunkName: "customer" */ './view/Home.vue');



let routes = [];
routes = routes.concat(PaymentRoutes);

routes = routes.concat([{
    path: 'home',
    name: 'customer-home',
    component: Home,
  },
  {
    path: 'list',
    name: 'customer-list-payment',
    component: List,
  },
  {
    path: 'deleted',
    name: 'deleted-customer-list',
    component: DeletedList,
  },
  {
    path: ':id/profile',
  name: 'customer-profile',
    component: Profile,
  },
]);

export default [{
  path: '/customer',
  redirect: { name: 'customer-home' },
  component: Index,
  children: routes,
}];

