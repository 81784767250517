
const Index = () => import(/* webpackChunkName: "attachment" */ './index.vue');
const List = () => import(/* webpackChunkName: "attachment" */ './components/List.vue');


export default [
  {
    path: 'attachment',
    name: 'attachment',
    component: Index,
    title: 'attachment',
    redirect: { name: 'attachment-list' },
    children: [
      
      {
        path: 'list',
        name: 'attachment-list',
        component: List,
      },
      
    ],
  },
]
