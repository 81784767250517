
const Index = () => import(/* webpackChunkName: "purchase" */ './index.vue');
const Create = () => import(/* webpackChunkName: "purchase" */ './components/Create.vue');
const List = () => import(/* webpackChunkName: "purchase" */ './components/List.vue');
const View = () => import(/* webpackChunkName: "purchase" */ './components/View.vue');
const Edit = () => import(/* webpackChunkName: "purchase" */ './components/Edit.vue');


export default [
  {
    path: 'purchase',
    name: 'purchase',
    component: Index,
    title: 'Purchase',
    redirect: { name: 'purchase-list' },
    children: [
      {
        path: 'create',
        name: 'purchase-create',
        component: Create,
        props: true,
      },
      {
        path: 'list',
        name: 'purchase-list',
        component: List,
      },
      {
        path: ':id/view',
        name: 'purchase-view',
        component: View,
      },
      {
        path: ':id/edit',
        name: 'purchase-edit',
        component: Edit,
      },
    ],
  },
]
