import backofficeService from './api/backoffice'
const errorSound = new Audio('https://s3.amazonaws.com/4.0image/errorSound.mp3');

export default {
  namespaced: true,
  state: {
    currentUser: null,
    currentBusiness: null,
    globalStatus: true,
    loginDialog: false,
    loadingStatus: {
      status: false,
      title: 'Please Wait',
    },
    type: null,
  },
  getters: {
    getType(state) {
      return state.type
    },
    loginDialog(state) {
      return state.loginDialog
    },
    globalStatus(state) {
      return state.globalStatus
    },
    loadingStatus(state) {
      return state.loadingStatus
    },
    checkRightStatus: (state) => (id) => {
      let returnValue = false
      // console.log('state.currentUser', state.currentUser, id)
      if (state.currentUser) {
        if (state.currentUser.isAdmin) {
          returnValue = true
        } else {
          let userRight = state.currentUser.userRights.split(",")
          userRight.map(row => {
            if (Number(row) === id) {
              returnValue = true
            }
          })
        }
      }


      return returnValue
    },
    currentUser(state) {
      return state.currentUser
    },
    currentBusiness(state) {
      return state.currentBusiness
    },
  },
  actions: {
    PlaySound(payload) {
      switch (payload) {
        case 'error':
          errorSound.play()
          break;
        // case 'keyPress':
        //   keyPress.play()
        //   break;
        // case 'deleteRow':
        //   deleteRow.play()
        //   break;
        // case 'checkout':
        //   Checkout.play()
        //   break;

        default:
          errorSound.play()
          // PlayRandomKeyPress(state)
          break;
      }

      // function PlayRandomKeyPress(state) {
      //   if (state.soundMode) {
      //     const myArray = ['key1', 'key2', 'key3'];
      //     const randomItem = myArray[Math.floor(Math.random() * myArray.length)];
      //     switch (randomItem) {
      //       case 'key1':
      //         key1.play();
      //         break;
      //       case 'key2':
      //         key2.play();
      //         break;
      //       case 'key3':
      //         key3.play();
      //         break;
      //       default:
      //         key1.play();
      //         break;
      //     }
      //   }
      // }
    },
    setGlobalStatus({
      commit
    }, payload) {
      commit('setGlobalStatus', payload)
    },
    setPageTitle(title) {
      document.title = title
    },
    setLoginDialog({
      commit
    }, payload) {
      commit('setLoginDialog', payload)
    },
    setLoadingStatus({
      commit
    }, payload) {
      commit('setLoadingStatus', payload)
    },
    initType({
      commit
    }) {
      return backofficeService.Api().get('/type')
        .then((response) => {
          commit('initType', response.data)

        })
    },
    initUser({
      commit
    }) {
      return backofficeService.Api().get('/user/current')
        .then((response) => {
          commit('initUser', response.data.user)
          commit('initBusiness', response.data.business)

        })
    },
    initBusiness({
      commit
    }, payload) {
      commit('initBusiness', payload)
    },
  },
  mutations: {
    initType(state, payload) {
      state.type = payload
    },
    setSearch(state, payload) {
      state.search = payload
    },
    initUser(state, payload) {
      state.currentUser = payload
    },
    initBusiness(state, payload) {
      state.currentBusiness = payload
    },
    setGlobalStatus(state, payload) {
      state.globalStatus = payload
    },
    setLoginDialog(state, payload) {
      state.loginDialog = payload
    },
    setLoadingStatus(state, payload) {
      state.loadingStatus = payload
    },
    initOrganization(state, payload) {
      state.currentOrganization = payload
    },
  },
}