// utils.js

export function highlight(words, query) {
  if (query) {
    // Convert query to lowercase and split it into search terms
    var queries = query.toLowerCase().split(/\s+/).filter(Boolean);
    
    // Escape special characters in search terms for use in RegExp
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    // Combine search terms into a single regular expression
    var combinedQuery = queries.map(term => escapeRegExp(term)).join('|');
    var combinedRegExp = new RegExp(`(${combinedQuery})`, 'ig');
    
    if (words) {
      // Use the combined regular expression to replace matching text
      return words.toString().replace(combinedRegExp, function(matchedText) {
        return `<span class="highlight">${matchedText}</span>`;
      });
    } else {
      return words;
    }
  } else {
    return words;
  }
}