import Index from './index.vue'
// import Create from './components/Create.vue'
import List from './components/List.vue'
// import Edit from './components/Edit.vue'

export default [
  {
    path: 'payee',
    name: 'payee',
    component: Index,
    title: 'payee',
    redirect: { name: 'payee-list' },
    children: [
      
      {
        path: 'list',
        name: 'payee-list',
        component: List,
      },
      
    ],
  },
]
