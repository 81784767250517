
const Index = () => import(/* webpackChunkName: "report" */ './index.vue');

import customerRoutes from '@/modules/ReportView/components/customer/router'
import profitRoutes from '@/modules/ReportView/components/profitAndLost/router'
import invoiceRoutes from '@/modules/ReportView/components/invoice/router'
import orderRoutes from '@/modules/ReportView/components/order/router'
import saleRoutes from '@/modules/ReportView/components/sale/router'
import purchaseRoutes from '@/modules/ReportView/components/purchase/router.js'
import productRoutes from '@/modules/ReportView/components/product/router'
import expenseRoutes from '@/modules/ReportView/components/expense/router'
import paymentRoutes from '@/modules/ReportView/components/payment/router'
import inventoryRoutes from '@/modules/ReportView/components/inventory/router'


let childrenRoutes = []

childrenRoutes = childrenRoutes.concat(customerRoutes)
childrenRoutes = childrenRoutes.concat(invoiceRoutes)
childrenRoutes = childrenRoutes.concat(orderRoutes)
childrenRoutes = childrenRoutes.concat(profitRoutes)
childrenRoutes = childrenRoutes.concat(saleRoutes)
childrenRoutes = childrenRoutes.concat(purchaseRoutes)
childrenRoutes = childrenRoutes.concat(expenseRoutes)
childrenRoutes = childrenRoutes.concat(paymentRoutes)
childrenRoutes = childrenRoutes.concat(productRoutes)
childrenRoutes = childrenRoutes.concat(inventoryRoutes)


export default [{
  path: '/reportView',
  name: 'report-view',
  component: Index,
  children: childrenRoutes
}, ]